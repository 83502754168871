import type { AutomationTemplateCategory, OptionCategory, AutomationOption } from '#automation/types'

export enum AutomationOperator {
  SET = 'SET',
  ADD = 'ADD',
  MOVE = 'MOVE',
  CLEAR = 'CLEAR',

  CLEARED = 'CLEARED',
  IS = 'IS',
  CHANGED = 'CHANGED',
  NOT = 'NOT',
  IN = 'IN',
  EMPTY = 'EMPTY',
  NOT_EMPTY = 'NOT_EMPTY',

  BEFORE = 'BEFORE',
  AFTER = 'AFTER',
  BETWEEN = 'BETWEEN',
  TODAY = 'TODAY',
  ONE_DAY = 'ONE_DAY',
  THREE_DAYS = 'THREE_DAYS',
  WEEK = 'WEEK',
}

export enum AutomationOptionType {
  TRIGGER = 'TRIGGER',
  ACTION = 'ACTION',
  CONDITION = 'CONDITION'
}

export enum AutomationTriggerType {
  ON_ACTION = 'ON_ACTION',
  BUTTON = 'BUTTON',
  TIME = 'TIME',
}

export enum TriggerType {
  TASK_ADDED_TO_BOARD = 'task_added_to_board',
  TASK_MOVED_TO_SECTION = 'task_moved_to_section',
  DUE_DATE_ARRIVES = 'due_date_arrives',
  DUE_DATE_OVERDUE = 'due_date_overdue',
  DUE_DATE_CHANGED = 'due_date_changed',
  ASSIGNEE_ADDED = 'assignee_added',
  CUSTOM_FIELD_CHANGED = 'custom_field_changed',
  STATUS_CHANGED = 'status_changed',
}

export enum ActionType {
  MOVE_TO_BOARD = 'move_to_board',
  MOVE_TO_SECTION = 'move_to_section',
  CHANGE_ASSIGNEE = 'change_assignee',
  CHANGE_DUE_DATE = 'change_due_date',
  SET_CUSTOM_FIELD = 'set_custom_field',
  CHANGE_STATUS = 'change_status',
  CREATE_SUBTASK = 'create_subtask',
  DUPLICATE_TASK = 'duplicate_task',
}

export enum ConditionType {
  TASK_IN_SECTION = 'task_in_section',
  TASK_CREATED_BY = 'task_created_by',
  DUE_DATE_IS = 'due_date_is',
  ASSIGNEE_IS = 'assignee_is',
  CUSTOM_FIELD_IS = 'custom_field_is',
  TASKTYPE_IS = 'tasktype_is',
  TASK_NO_BLOCKED = 'task_no_blocked',
  LABEL_IS = 'label_is',
}

export const TriggerOptions: OptionCategory[] = [
  {
    label: 'Add or move',
    options: [
      {
        optionType: AutomationOptionType.TRIGGER,
        triggerType: AutomationTriggerType.ON_ACTION,
        type: TriggerType.TASK_ADDED_TO_BOARD,
        label: 'Task is added to this board',
        icon: 'i-lucide:plus'
      },
      {
        optionType: AutomationOptionType.TRIGGER,
        triggerType: AutomationTriggerType.ON_ACTION,
        type: TriggerType.TASK_MOVED_TO_SECTION,
        label: 'Task is moved to a section',
        icon: 'i-lucide:arrow-right',
      },
    ] as AutomationOption[]
  },
  {
    label: 'Date & Times',
    options: [
      {
        optionType: AutomationOptionType.TRIGGER,
        triggerType: AutomationTriggerType.TIME,
        type: TriggerType.DUE_DATE_ARRIVES,
        label: 'Due date arrives',
        icon: 'i-lucide:alarm-clock',
      },
      {
        optionType: AutomationOptionType.TRIGGER,
        triggerType: AutomationTriggerType.TIME,
        type: TriggerType.DUE_DATE_OVERDUE,
        label: 'Due date overdue',
        icon: 'i-lucide:alarm-clock',
      },
      {
        optionType: AutomationOptionType.TRIGGER,
        type: TriggerType.DUE_DATE_CHANGED,
        triggerType: AutomationTriggerType.ON_ACTION,
        label: 'Due date changed',
        icon: 'i-lucide:calendar-cog',
      }
    ] as AutomationOption[]
  },
  {
    label: 'Task management',
    options: [
      {
        optionType: AutomationOptionType.TRIGGER,
        type: TriggerType.ASSIGNEE_ADDED,
        triggerType: AutomationTriggerType.ON_ACTION,
        label: 'Assignee added',
        icon: 'i-lucide:user-plus',
      },
      {
        optionType: AutomationOptionType.TRIGGER,
        type: TriggerType.CUSTOM_FIELD_CHANGED,
        triggerType: AutomationTriggerType.ON_ACTION,
        label: 'Custom field changes',
        icon: 'i-lucide:rectangle-ellipsis',
      },
      {
        optionType: AutomationOptionType.TRIGGER,
        type: TriggerType.STATUS_CHANGED,
        triggerType: AutomationTriggerType.ON_ACTION,
        label: 'Status is changed',
        icon: 'i-lucide:circle-dot',
      },
    ] as AutomationOption[]
  }
]

export const ActionOptions: OptionCategory[] = [
  {
    label: 'Add or move',
    options: [
      {
        optionType: AutomationOptionType.ACTION,
        type: ActionType.MOVE_TO_BOARD,
        label: 'Move to board',
        icon: 'lucide:arrow-right',
      },
      {
        optionType: AutomationOptionType.ACTION,
        type: ActionType.MOVE_TO_SECTION,
        label: 'Move to section',
        icon: 'lucide:circle-arrow-right',
      },
    ] as AutomationOption[]
  },
  {
    label: 'Change task field',
    options: [
      {
        optionType: AutomationOptionType.ACTION,
        type: ActionType.CHANGE_ASSIGNEE,
        label: 'Change assignee to',
        icon: 'i-lucide:user-plus',
      },
      {
        optionType: AutomationOptionType.ACTION,
        type: ActionType.CHANGE_DUE_DATE,
        label: 'Change due date to',
        icon: 'i-lucide:calendar-cog',
      }
    ] as AutomationOption[]
  },
  {
    label: 'Task management',
    options: [
      {
        optionType: AutomationOptionType.ACTION,
        type: ActionType.SET_CUSTOM_FIELD,
        label: 'Set custom field to',
        icon: 'i-lucide:rectangle-ellipsis',
      },
      {
        optionType: AutomationOptionType.ACTION,
        type: ActionType.CHANGE_STATUS,
        label: 'Change status',
        icon: 'i-lucide:circle-dot',
      },
      {
        optionType: AutomationOptionType.ACTION,
        type: ActionType.CREATE_SUBTASK,
        label: 'Create subtask',
        icon: 'leanbase:subtask-outline',
      },
      {
        optionType: AutomationOptionType.ACTION,
        type: ActionType.DUPLICATE_TASK,
        label: 'Duplicate',
        icon: 'i-heroicons:document-duplicate',
      }
    ] as AutomationOption[]
  }
]

export const ConditionOptions: OptionCategory[] = [
  {
    label: 'Add or move',
    options: [
      {
        optionType: AutomationOptionType.CONDITION,
        type: ConditionType.TASK_IN_SECTION,
        label: 'Task is in section ...',
        icon: 'i-lucide:arrow-right',
      },
      {
        optionType: AutomationOptionType.CONDITION,
        type: ConditionType.TASK_CREATED_BY,
        label: 'Task is created by ...',
        icon: 'i-lucide:plus',
      },
    ] as AutomationOption[]
  },
  {
    label: 'Date & Times',
    options: [
      {
        optionType: AutomationOptionType.CONDITION,
        type: ConditionType.DUE_DATE_IS,
        label: 'Due date is ...',
        icon: 'i-lucide:alarm-clock',
      },
    ] as AutomationOption[]
  },
  {
    label: 'Task management',
    options: [
      {
        optionType: AutomationOptionType.CONDITION,
        type: ConditionType.ASSIGNEE_IS,
        label: 'Assignee is ...',
        icon: 'i-lucide:user-plus',
      },
      {
        optionType: AutomationOptionType.CONDITION,
        type: ConditionType.CUSTOM_FIELD_IS,
        label: 'Custom field is ...',
        icon: 'i-lucide:rectangle-ellipsis',
      },
      {
        optionType: AutomationOptionType.CONDITION,
        type: ConditionType.TASKTYPE_IS,
        label: 'Task type is ...',
        icon: 'i-lucide:square-check-big',
      },
      {
        optionType: AutomationOptionType.CONDITION,
        type: ConditionType.TASK_NO_BLOCKED,
        label: 'Task is no longer blocked',
        icon: 'i-lucide:ban',
      },
      {
        optionType: AutomationOptionType.CONDITION,
        type: ConditionType.LABEL_IS,
        label: 'Label is ...',
        icon: 'i-lucide:tag',
      },
    ] as AutomationOption[]
  }
]

const TriggerOptionsFlat = TriggerOptions.map(option => option.options).flat()
const ActionOptionsFlat = ActionOptions.map(option => option.options).flat()

export enum AutomationCategories {
  WORKFLOW = 'Workflow',
  SLACK = 'Slack intergration',
}

export const AutomationTemplates: AutomationTemplateCategory[] = [
  {
    name: AutomationCategories.WORKFLOW,
    options: [
      {
        description: 'Task is added to this board -> Move task to a certain section',
        trigger: TriggerOptionsFlat.find(option => option.type === TriggerType.TASK_ADDED_TO_BOARD) as AutomationOption,
        action: ActionOptionsFlat.find(option => option.type === ActionType.MOVE_TO_SECTION) as AutomationOption
      },
      {
        description: 'Task is move to a section -> Set custom field value to',
        trigger: TriggerOptionsFlat.find(option => option.type === TriggerType.TASK_MOVED_TO_SECTION) as AutomationOption,
        action: ActionOptionsFlat.find(option => option.type === ActionType.SET_CUSTOM_FIELD) as AutomationOption
      },
      {
        description: 'Task or all subtasks status is changed -> Move task to a certain section',
        trigger: TriggerOptionsFlat.find(option => option.type === TriggerType.STATUS_CHANGED) as AutomationOption,
        action: ActionOptionsFlat.find(option => option.type === ActionType.MOVE_TO_SECTION) as AutomationOption
      },
      {
        description: 'Task is move to a section -> Create subtasks',
        trigger: TriggerOptionsFlat.find(option => option.type === TriggerType.TASK_MOVED_TO_SECTION) as AutomationOption,
        action: ActionOptionsFlat.find(option => option.type === ActionType.CREATE_SUBTASK) as AutomationOption
      },
      {
        description: 'Task is move to a section -> Set Status to',
        trigger: TriggerOptionsFlat.find(option => option.type === TriggerType.TASK_MOVED_TO_SECTION) as AutomationOption,
        action: ActionOptionsFlat.find(option => option.type === ActionType.CHANGE_STATUS) as AutomationOption
      },
      {
        description: 'Task is move to a section -> Change assignee to',
        trigger: TriggerOptionsFlat.find(option => option.type === TriggerType.TASK_MOVED_TO_SECTION) as AutomationOption,
        action: ActionOptionsFlat.find(option => option.type === ActionType.CHANGE_ASSIGNEE) as AutomationOption
      }
    ]
  },
  {
    name: AutomationCategories.SLACK,
    options: []
  }
]
