import type { AutomationOptionRaw, AutomationRule, AutomationRuleRaw } from '#automation/types'

export const normalizeParams = (ruleRaw: AutomationRule) => {
  if ('modified' in ruleRaw) {
    delete ruleRaw.modified
  }

  if ('__typename' in ruleRaw) {
    delete ruleRaw.__typename
  }

  if ('__typename' in ruleRaw.trigger) {
    delete ruleRaw.trigger.__typename
  }

  if (ruleRaw.condition && '__typename' in ruleRaw.condition) {
    delete ruleRaw.condition.__typename
  }

  ruleRaw.actions.forEach(action => {
    if ('__typename' in action) {
      delete action.__typename
    }
  })

  return {
    ...ruleRaw,
    trigger: {
      ...ruleRaw.trigger,
      triggerType: undefined,
      optionType: undefined,
      targetValue: JSON.stringify(ruleRaw.trigger?.targetValue),
      targetData: JSON.stringify(ruleRaw.trigger?.targetData),
    } as AutomationOptionRaw,
    actions: ruleRaw.actions.map((action, index) => {
      return {
        ...action,
        optionType: undefined,
        targetValue: JSON.stringify(action.targetValue),
        targetData: JSON.stringify(action.targetData),
        index
      } as AutomationOptionRaw
    }),
    condition: ruleRaw.condition
      ? {
        ...ruleRaw.condition,
        optionType: undefined,
        targetValue: JSON.stringify(ruleRaw.condition?.targetValue),
        targetData: JSON.stringify(ruleRaw.condition?.targetData),
      } as AutomationOptionRaw
      : null,
  } as AutomationRuleRaw
}
